import * as React from "react"
import { PROJECTS } from "../../../../constants/constants"
import Header from "../../../Header/Header"
import ProjectBlock from "../../../UI/ProjectBlock/ProjectBlock"
import ProjectFooter from "../../../UI/ProjectFooter/ProjectFooter"
import ProjectGoals from "../../../UI/ProjectGoals/ProjectGoals"
import ProjectIntro from "../../../UI/ProjectIntro/ProjectIntro"
import IntroGraphics from "./IntroGraphics/IntroGraphics"

import "./OneFile.sass"

const challenges = [
  "Integration with multiple email providers",
  "Fast mailboxes synchronization",
  "Automatic emails and files sorting",
  "Processing vast amount of data",
  "Storage cost optimization",
  "Optimized UI components to work with millions of records",
]

const solutions = [
  "Ultra task-specific services that scale",
  "Micro-service architecture in Kubernetes",
  "Custom UI to deliver <16ms response time",
  "Distributed storage solution for storing petabytes of data",
  "Machine Learning for Smart Categorization",
]

class OneFile extends React.Component {

  render() {

    const info = [
      { label : "Client", value : "1File" },
      { label : "Year", value : "2019" },
      { label : "Category", value : "Web App / Mobile App / Website" },
      { label  : "Website Url",
        value : "https://app.1file.com/",
        linkLabel : "app.1file.com",
        isLink : true,
      },
    ]

    return (
      <main className='onefile'>
        <Header/>
        <ProjectIntro title='1File'
                      description='1File is a service that helps you organize and manage emails and files you have. Smart, intuitive and flexible tool, it aggregates emails and attachments in one place, organizes them into folders automatically, and allows you to create your own using specific rules.'
                      heading='heading-case'
                      info={info}>
          <IntroGraphics/>
        </ProjectIntro>
        <ProjectGoals challenges={challenges} solutions={solutions}/>
        <ProjectBlock className='onefile__email'
                      heading='heading-num1'
                      title='Email Providers Import'
                      subtitle='We created connectors for every major ISP to synchronize emails and their statuses in real time. 1File has a distributed import queue and can process over 100,000 emails per second. '
                      isImageFirst={true}>
          <img src='../../../../images/projects/onefile/multiple-email.png'
               alt='Screenshots: 1File multiple email'/>
        </ProjectBlock>
        <ProjectBlock className='onefile__organized'
                      heading='heading-num2'
                      title='Sorting & Organizing'
                      centered={true}
                      overlappedHeading
                      subtitle='1File process content of each imported email and create folders based on the intensity of the conversations you have. In addition, we created tools for you to manually sort, stack, arrange, banish, combine and delete the data - all your way.'
        />
        <div className='onefile__navigator-desktop'>
          <img src='../../../../images/projects/onefile/organized-your-way.png'
               alt='Screenshots: 1File organized your way'/>
        </div>
        <ProjectBlock className='onefile__sophisticated-search max-width'
                      heading='heading-num3'
                      title='Sophisticated Search'
                      subtitle={`Utilizing ElasticSearch and MongoDB we created flexible and convenient search that allows you to use date, file extension, recipients information, automatic folders or message content at the same time. You can also narrow down the search results with extra filters and sorting parameters to find that one item you've been looking for!`}

                      image={{
                        src : "../../../../images/projects/onefile/sophisticated-search.png",
                        alt : "Screenshots: 1File sophisticated search",
                      }}/>
        <ProjectBlock className='onefile__mobile-app'
                      heading='heading-num4'
                      title='Mobile Apps'
                      subtitle='Mobile App for 1File has a very high performance bar that was only possible to achieve using cutting edge algorithms and data structures.'
                      isImageFirst={true}
                      overlappedHeading
                      headerCentered
                      image={{
                        src : "../../../../images/projects/onefile/mobile-apps.png",
                        alt : "Screenshots: 1File mobile apps",
                      }}/>
        <ProjectFooter title='UVCA'
                       className='onefile__footer'
                       link='#'
                       commingSoon
                       imageSrc='../../../../images/projects/uvca-footer.png'
                       imageAlt='Image: polygonal sphere with links'
        />
      </main>
    )
  }
}

export default OneFile
