import React, { Component } from "react"
import PageWrapper from "../components/PageWrapper/PageWrapper"
import SEO from "../components/seo"
import OneFile from "../components/Pages/Projects/OneFile/OneFile"

class OneFilePage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='1File'
          description='1File is a service that helps you organize and manage emails and files you have. Smart, intuitive and flexible tool, it aggregates emails and attachments in one place, organizes them into folders automatically, and allows you to create your own using specific rules.'
        />
        <OneFile/>
      </PageWrapper>
    )
  }
}

export default OneFilePage
