import THREE from "three"

export let Light = function(props) {
  this.light = [
    new THREE.PointLight(props.point.color, 0.2, 0),
    new THREE.PointLight(props.point.color, 0.2, 0),
    new THREE.PointLight(props.point.color, 0.15, 0),
    new THREE.AmbientLight(props.ambient.color),
  ]
  this.light[0].position.set(100, 200, 200)
  this.light[1].position.set(150, -200, 300)
  this.light[2].position.set(-200, -200, 200)

  this.helpers = []
  if (props.helpers) {
    this.helpers = [
      new THREE.PointLightHelper(this.light[0]),
      new THREE.PointLightHelper(this.light[1]),
      new THREE.PointLightHelper(this.light[2]),
    ]
  }
}

export let Icosahedron = function(props) {
  this.object = new THREE.Object3D()

  this.meshes = []
  props.inner && this.meshes.push(new THREE.Mesh(props.inner.geometry, props.inner.material))
  props.outer && this.meshes.push(new THREE.Mesh(props.outer.geometry, props.outer.material))
  this.meshes.map(m => this.object.add(m))

  this.dots = []
  if (props.dot) {
    props.inner && props.inner.geometry.vertices.map((vertex, i) => {
      this.dots.push(new THREE.Mesh(props.dot.geometry, props.dot.material))
      this.dots[i].position.x = vertex.x
      this.dots[i].position.y = vertex.y
      this.dots[i].position.z = vertex.z
    })

    let iShift = props.inner ? props.inner.geometry.vertices.length : 0
    props.outer && props.outer.geometry.vertices.map((vertex, i) => {
      this.dots.push(new THREE.Mesh(props.dot.geometry, props.dot.material))
      this.dots[i + iShift].position.x = vertex.x
      this.dots[i + iShift].position.y = vertex.y
      this.dots[i + iShift].position.z = vertex.z
    })

    this.dots.map(d => this.object.add(d))

    if (props.inner && props.outer) {
      let lines = []
      props.inner.geometry.vertices.map((vertex, i) => {
        let vertices = {
          inner : props.inner.geometry.vertices[i],
          outer : props.outer.geometry.vertices[i],
        }

        let geometry = new THREE.Geometry()
        geometry.vertices.push(
          new THREE.Vector3(vertices.inner.x, vertices.inner.y, vertices.inner.z),
          new THREE.Vector3(vertices.outer.x, vertices.outer.y, vertices.outer.z),
        )
        let material = new THREE.LineBasicMaterial({ color : props.dot.material.color })

        lines.push(new THREE.Line(geometry, material))
      })
      lines.map(l => this.object.add(l))
    }
  }
}

export default {
  Light,
  Icosahedron,
}