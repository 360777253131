import React, { Component } from "react"
import { Light, Icosahedron } from "./builder3d"

import THREE from "three"

const degToRad = (degrees) => {
  return degrees * Math.PI / 180
}

let OPTIONS = {
  antialias : true,

  fov  : 10,
  near : 2000,
  far  : 10000,

  cam    : {
    pos : {
      x : 0,
      y : 0,
      z : 3800,
    },
  },
  object : {
    rotationSpeed : 0.03,
  },
  mesh   : {
    inner : {
      radius : 150,
      detail : 0,
      color  : 0xB6D5E3,
    },
    outer : {
      radius : 250,
      detail : 0,
      color  : 0xCFDEE6,
    },
    dot   : {
      radius : 4,
      color  : 0xCFDEE6,
    },
  },
  light  : {
    point   : { color : 0xD4D4D4 },
    ambient : { color : 0xFFF5EA },
  },
}

class IntroGraphics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width  : 500,
      height : 500,

      renderer : null,
      scene    : null,
      camera   : null,
      object   : null,

      mesh : {
        inner : {
          geometry : new THREE.IcosahedronGeometry(
            OPTIONS.mesh.inner.radius,
            OPTIONS.mesh.inner.detail,
          ),
          material : new THREE.MeshPhongMaterial({
            color   : OPTIONS.mesh.inner.color,
            shading : THREE.FlatShading,
          }),
        },
        outer : {
          geometry : new THREE.IcosahedronGeometry(
            OPTIONS.mesh.outer.radius,
            OPTIONS.mesh.outer.detail,
          ),
          material : new THREE.MeshBasicMaterial({
            wireframe : true,
            color     : OPTIONS.mesh.outer.color,
          }),
        },
        dot   : {
          geometry : new THREE.SphereGeometry(OPTIONS.mesh.dot.radius, 32, 32),
          material : new THREE.MeshBasicMaterial({
            color : OPTIONS.mesh.dot.color,
          }),
        },
      },
    }

    this.mouse       = { x : 0, y : 0 }
    this.willUnmount = false


    this.init           = this.init.bind(this)
    this.animationTick  = this.animationTick.bind(this)
    this.onMouseMove    = this.onMouseMove.bind(this)
    this.onWindowResize = this.onWindowResize.bind(this)
  };


  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize)
    this.onWindowResize()
    setTimeout(this.init, 100)
  }

  componentWillUnmount() {
    this.willUnmount = true
    window.removeEventListener("resize", this.onWindowResize)
  }


  init() {
    let { width, height, mesh } = this.state
    let holder                  = this.refs["holder"]
    if (!holder) return

    let renderer = new THREE.WebGLRenderer({ alpha : true, antialias : OPTIONS.antialias })
    renderer.setSize(width, height)
    holder.appendChild(renderer.domElement)

    let scene = new THREE.Scene()

    let light = new Light({
      point   : OPTIONS.light.point,
      ambient : OPTIONS.light.ambient,
    })
    light.light.map(l => scene.add(l))
    light.helpers.map(l => scene.add(l))

    let ratio         = width / height
    let camera        = new THREE.PerspectiveCamera(OPTIONS.fov, ratio, OPTIONS.near, OPTIONS.far)
    camera.position.x = OPTIONS.cam.pos.x
    camera.position.y = OPTIONS.cam.pos.y
    camera.position.z = OPTIONS.cam.pos.z

    camera.position.x -= Math.min(300, width / 4)

    let object = new Icosahedron(mesh)
    scene.add(object.object)

    this.setState({ renderer, scene, camera, object })
    this.animationTick()
  }

  animationTick() {
    if (this.willUnmount) return

    let { camera, object } = this.state
    let speed              = OPTIONS.object.rotationSpeed

    if (object) {
      object.object.rotation.x += degToRad(speed)
      object.object.rotation.y += degToRad(speed)

      this.setState({ camera, object })
    }

    requestAnimationFrame(this.animationTick)
  }

  onMouseMove(e) {
    this.mouse.x = (e.clientX)
    this.mouse.y = (e.clientY)
  }

  onWindowResize() {
    let { renderer, camera } = this.state

    let styles = getComputedStyle(document.querySelector(".intro-graphics-holder"))
    let width  = parseInt(styles.width, 10)
    let height = parseInt(styles.height, 10)

    if (renderer) {
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()
    }

    this.setState({ width, height })
  }


  render() {
    let { renderer, scene, camera } = this.state

    renderer && renderer.render(scene, camera)

    return <div className="intro-graphics-holder" ref="holder">
      <img src='../../../../../images/projects/onefile/onefile-logo.png'/>
    </div>
  }
}

export default IntroGraphics




